import { useMutation } from 'react-query';

import { authService } from 'services';

/**
 * Hook to mutate login by React Query
 *
 * @export
 * @return {*}
 */
export default function useMutationLogin() {
  return useMutation(({ email, password }: { email: string; password: string }) =>
    authService.login(email, password, 'OmbreApp')
  );
}
